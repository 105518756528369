import React, { FC } from 'react'
import { msalAuthProvider } from '../provider/msalAuthProvider'
import { SetZustimmungHygieneRichtlinie } from '../data/dataAccess'
import HygieneRichtlinieText from './hygieneRichtlinieText'
import HygieneRichtlinieTextEng from './hygieneRichtlinieTextEng'
import styles from './styles/zustimmungHygieneRichtlinie.module.css'
import Dsvgo from './dsvgo'
import DsvgoEng from './dsvgoEng'
interface Props {
}
const ZustimmungErteilen: FC<Props> = (props) => {
    const [checkboxSelected, setChekboxSelected] = React.useState(false)
    const [requestSuccess, setRequestSuccess] = React.useState(0)
    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChekboxSelected(event.currentTarget.checked)
    }
    const handleSubmitButtonClicked = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const token = await msalAuthProvider.getAccessToken()
        if (token !== null) {
            const result = await SetZustimmungHygieneRichtlinie(token.accessToken) as boolean
            setRequestSuccess(result ? 1 : -1)
        }
    }
    if (requestSuccess === 1)
        return (<div>Zustimmung wurde erfolgreich erteilt</div>)
    return (<div>
        <HygieneRichtlinieText />
        <HygieneRichtlinieTextEng />
        <div className={styles.zustimmungContainer}>
            <div className={styles.zustimmungCheckbox}>
                <input type='checkbox' onChange={handleCheckBoxChange} />
            </div>
            <div className={styles.zustimmungLabel}>
                Hiermit stimme ich der <a href='https://processes.fh-krems.ac.at/ims.net/Common/GetDoc.aspx?modId=378722&lang=DE' target='_blank' rel="noopener noreferrer">COVID-19 Hygienemaßnahmen und Richtlinien</a> zu/I herby approve of the <a href='https://processes.fh-krems.ac.at/ims.net/Common/GetDoc.aspx?modId=378722&lang=EN' target='_blank' rel="noopener noreferrer">COVID-19 hygiene measures and guidelines</a></div><br />
            {requestSuccess === -1 && <div>Erteilung der Zustimmung nicht möglich. Fehler Webservice</div>}
            <button disabled={checkboxSelected === false} onClick={handleSubmitButtonClicked} className={checkboxSelected ? styles.submitButtonCheckboxChecked : styles.submitButton}>COVID-19 Hygienemaßnahmen und Richtlinien zustimmen / <br/>approve of COVID-19 Hygiene measure and guidelines</button>
        </div>
        <Dsvgo />
        <DsvgoEng />
    </div>)
}

export default ZustimmungErteilen