import React, { FC } from 'react'
import styles from './styles/hygieneRichtlinieText.module.scss'

interface Props {
}
const DsvgoEng: FC<Props> = (props) => {
    return (
        <>
            <div className={styles.spanDsvgo}><b>Information according to DSGVO</b></div>
            <div className={styles.spanDsvgo}>
                The Federal Ministry of Education, Science and Research (BMBWF) has published a "Hygiene Manual for COVID-19" (hereinafter referred to as "Hygiene Manual"). This manual contains recommendations for universities and colleges in Austria to minimize the risk of infection with COVID-19. It is recommended that the university managements implement the measures listed therein. By adhering to the measures mentioned, (classroom) teaching at the University of Applied Sciences should be made possible with reduced personal contacts to other persons.
                According to the hygiene handbook, all students are to be presented with an information sheet when they first enter their place of study or research institution or other organised presence, the contents of which are to be acknowledged by signing.
                <br /><br />This involves the processing of personal data, so that the following data protection information must be provided in addition to the content information according to the hygiene handbook:
                <ul>
                    <li>Responsible for data processing: IMC FH Krems, <a href='mailto:datenschutz@fh-krems.ac.at'>datenschutz@fh-krems.ac.at</a></li>
                    <li>Purpose: Implementation of the recommended measures according to the hygiene handbook of the BMBWF</li>
                    <li>Stored data: Name, course of study, matriculation number, date of acknowledgement</li>
                    <li>Legal basis: Predominant legitimate interest of the Fachhochschule (Article 6/1/f DSGVO), which is based on the recommendation for action according to the hygiene manual of the BMBWF</li>
                    <li>Processor: Microsoft Corporation as cloud provider (individual IT services and parts of the technical support may take place in an EU third country (USA)).</li>
                    <li>Data recipient: Controlling institutions (e.g.: BMBWF) in case of an audit of the implementation of measures at the university of applied sciences.</li>
                    <li>Retention period: the data shall be kept for as long as necessary to maintain the measures in force in respect of Covid-19</li>
                    <li>Rights of data subjects: You have the right of access, rectification, cancellation, restriction of processing, opposition and appeal, under the conditions set out in the relevant provisions.</li>
                </ul>
            </div>
            <div className={styles.spanDsvgo}>
                As long as knowledge of the information sheet (by means of a checkbox on the e-desktop) is not confirmed or such confirmation is not received, access to the affected premises of the University of Applied Sciences cannot be granted.
            </div>
        </>
    )
}
export default DsvgoEng