import React, { FC } from 'react'
import styles from './styles/hygieneRichtlinieText.module.scss'

interface Props {
}
const HygieneRichtlinieTextEng: FC<Props> = (props) => {
    return (
        <React.Fragment>
            <h1>Approval COVID-19 Hygiene measures and guidelines for presence lectures, practical and laboratory exercises IMC UAS Krems</h1>
            <div className={styles.hygieneRichtlinieText}>
                <div className={styles.header}>Hygiene measures and guidelines:</div>
                <div className={styles.span}>We are pleased that students of all study courses will be able to take part in the <b>presence lectures, practical and laboratory exercises at the IMC UAS Krems.</b> Please check your timetable carefully when and especially where your courses take place, as all buildings of IMC FH Krems can be used. Please also note that access to our building is only possible with your student ID card.</div>
                <div className={styles.span}>Your health is of course particularly important to us during these special times, which is why we hold your courses only by <b>adhering strictly to special hygiene measures and guidelines.</b> In this respect, we will adhere to the guidelines and recommendations of the Federal Ministry. In order to guarantee the protective measures for students, lecturers and staff, the following hygiene measures and guidelines have been drawn up for the IMC UAS Krems, which must be expressly observed by all authorised persons present in the buildings of IMC FH Krems. This is intended to prevent infection with COVID-19.</div>
                <div className={styles.span}><b>Please read our guidelines carefully and take explicit note of them.</b></div>
                <div className={styles.span}>You can find the <a href='https://processes.fh-krems.ac.at/ims.net/Common/GetDoc.aspx?modId=378722&lang=EN' target='_blank' rel="noopener noreferrer">hygiene guidelines</a> at any time in the COVID-19 FAQs on the eDesktop. Please try an alternative browser, i.e. Google Chrome or Microsoft Teams should the links not work.</div>
                <div className={styles.span}>If you have any questions regarding the schedule, please contact <a href='mailto:studyservices@fh-krems.ac.at'>studyservices@fh-krems.ac.at</a>. For questions about the hygiene measures, please contact <a href='mailto:info@fh-krems.ac.at'>info@fh-krems.ac.at</a>.</div>
                <div className={styles.span}>We wish you all the best and stay healthy!
                    <br />
                    Management team IMC FH Krems</div>
                <div className={styles.footer}>
                    <b>Do not only protect yourself, but also protect and respect all persons present by following the guidelines and measures!</b>
                </div>
            </div>
        </React.Fragment>
    )
}
export default HygieneRichtlinieTextEng