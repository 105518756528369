import React, { FC } from 'react'
import styles from './styles/hygieneRichtlinieText.module.scss'

interface Props {
}
const Dsvgo: FC<Props> = (props) => {
    return (
        <>
            <div className={styles.spanDsvgo}><b>Informationen gemäß DSGVO</b></div>
            <div className={styles.spanDsvgo}>
                Das Bundesministerium für Bildung, Wissenschaft und Forschung (BMBWF) hat ein „Hygienehandbuch zu COVID-19“ (in der Folge „Hygienehandbuch“) aufgelegt. Diesem sind Handlungsempfehlungen für Universitäten und Hochschulen in Österreich zu entnehmen, um die Gefahr einer Ansteckung an COVID-19 zu minimieren. Den Hochschulleitungen wird empfohlen, die darin angeführten Maßnahmen umzusetzen. Durch Einhaltung der genannten Maßnahmen soll der (Präsenz-)Betrieb der Fachhochschule unter reduzierten persönlichen Kontakten zu anderen Personen ermöglicht werden.
                <br />Laut <b>dem Hygienehandbuch ist allen Studierenden bei ihrem erstmaligen Betreten ihres Studienplatzes bzw. ihrer Forschungseinrichtung oder anderen organisierten Präsenzen ein Informationsblatt vorzulegen, dessen Inhalt durch Unterschriftsleistung zur Kenntnis</b> zu nehmen ist.
                <br /><br />Damit ist die Verarbeitung von personenbezogenen Daten verbunden, sodass ergänzend zu den inhaltlichen Informationen laut Hygienehandbuch folgende datenschutzrechtlichen Informationen zu erteilen sind:
                <ul>
                    <li>Verantwortlich für die bezughabende Datenverarbeitung, IMC FH Krems, <a href='mailto:datenschutz@fh-krems.ac.at'>datenschutz@fh-krems.ac.at</a></li>
                    <li>Zweck: Umsetzung der empfohlenen Maßnahmen laut Hygienehandbuch des BMBWF</li>
                    <li>Gespeicherte Daten: Namen, Studiengang, Matrikelnummer, Zeitpunkt der Kenntnisnahme</li>
                    <li>Rechtsgrundlage: überwiegende berechtigte Interesse der Fachhochschule (Art. 6/1/f DSGVO), die in der Entsprechung mit der Handlungsempfehlung laut Hygienehandbuch des BMBWF begründet sind.</li>
                    <li>Auftragsverarbeiter: Microsoft Corporation als Cloud-Anbieter (einzelne IT-Dienstleistungen und Teile des technischen Supports können in einem EU-Drittland (USA) stattfinden).</li>
                    <li>Datenempfänger: Kontrollinstitutionen (z.B.: BMBWF) im Falle einer Prüfung der Maßnahmen-Umsetzung in der Fachhochschule</li>
                    <li>Speicherfrist: die Daten sind so lange aufzubewahren, wie die Aufrechterhaltung der Maßnahmen in Bezug auf Covid-19 erforderlich ist.</li>
                    <li>Betroffenenrechte: Sie haben das Recht auf Auskunft, Berichtigung Löschung, Einschränkung der Verarbeitung, Widerspruch und Beschwerde, unter den in den entsprechenden Bestimmungen angeführten Bedingungen.</li>
                </ul>
            </div>
            <div className={styles.spanDsvgo}>
                Solange die Kenntnisnahme des Informationsblatts (mittels Checkbox am e-desktop) nicht bestätigt wird oder eine solche Bestätigung unterbleiben, kann der Zutritt zu den betroffenen Räumlichkeiten der Fachhochschule nicht gestattet werden.
            </div>
        </>
    )
}
export default Dsvgo