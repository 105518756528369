import React, { FC,  useEffect, useCallback } from "react"
import styles from './styles/zustimmungHygieneRichtlinie.module.css'
import Header from './header'
import ZustimmungBereitserteilt from './zustimmungBereitserteilt'
import ZustimmungErteilen from './zustimmungErteilen'
import ZustimmungRequestDataItem from '../data/zustimmungRequestDataItem'
import { msalAuthProvider } from "../provider/msalAuthProvider"
import {GetZustimmungHygieneRichtlinie} from '../data/dataAccess'
import InvalidUser from './invalidUser'
import Loader from "react-loader-spinner"

interface Props {    
    logout:()=>void
}
const ZustimmungHygieneRichtlinie: FC<Props> = (props) => {    
    const [zustimmungItem,setZustimmungItem] = React.useState({isLoading:true} as ZustimmungRequestDataItem)

    const fetchZustimmungBereitsErteilt = useCallback(async () => {
        const token = await msalAuthProvider.getAccessToken();
        const item = await GetZustimmungHygieneRichtlinie(token.accessToken)
        setZustimmungItem(item)
    }, []);  

    useEffect(() => {
        fetchZustimmungBereitsErteilt()
    }, [fetchZustimmungBereitsErteilt])
    return (<div className={styles.zustimmungHygieneRichtlinie}>
        <Header />
        <div className={styles.body}>
            {zustimmungItem.apiConnectionOK === true && zustimmungItem.userValid === true && zustimmungItem.datumZustimmung !== null && <ZustimmungBereitserteilt datum={zustimmungItem.datumZustimmung} />}
            {((zustimmungItem.apiConnectionOK === true && zustimmungItem.userValid === true && zustimmungItem.datumZustimmung === null) ) && <ZustimmungErteilen />}
            {zustimmungItem.apiConnectionOK === true && zustimmungItem.userValid === false && <InvalidUser/>}
            {zustimmungItem.isLoading === false && zustimmungItem.apiConnectionOK !== true && <div>Verbindungsprobleme bitte  <a href='https://selfservice.fh-krems.ac.at/zustimmunghygiene/'>erneut versuchen</a></div>}
            {zustimmungItem.isLoading === true && <Loader type="ThreeDots" color="#335784" height={80} width={80} />}
            {zustimmungItem.isLoading === false && zustimmungItem.userValid === false && <input type='button' onClick={()=>{props.logout()}} value='Logout'/>}
        </div>
    </div>)
}

export default ZustimmungHygieneRichtlinie