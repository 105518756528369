import React, { FC } from "react"
interface Props {
    datum:string
}
const ZustimmungBereitserteilt: FC<Props> = (props) => {
    return (<div>
        Den <a href='https://www.fh-krems.ac.at/fileadmin/public/downloads/allgemein/hygiene-massnahmen-imc-fh-krems.pdf' target='_blank' rel="noopener noreferrer">COVID-19 Hygienemaßnahmen und Richtlinien IMC FH Krems</a> wurde bereits am {props.datum} zugestimmt
        <br/>
        You have already approved the <a href='https://www.fh-krems.ac.at/fileadmin/public/downloads/allgemein/hygiene-measures-imc-krems.pdf' target='_blank' rel="noopener noreferrer">hygiene measures and guidelines</a>
    </div>)
}

export default ZustimmungBereitserteilt