import React, { StrictMode} from "react"
import './App.css'
import AzureAD, { IAzureADFunctionProps, AuthenticationState } from "react-aad-msal"
import { msalAuthProvider } from "./provider/msalAuthProvider"
import { context } from "./provider/context"
import ZustimmungHygieneRichtlinie from './components/zustimmungHygieneRichtlinie'

const App: React.FC = () => {

  return <StrictMode>
    <AzureAD provider={msalAuthProvider} forceLogin={true}>
      {({ login, logout, authenticationState, accountInfo }: IAzureADFunctionProps) => {
        switch (authenticationState) {
          case AuthenticationState.Unauthenticated:
            return <div className="App">
              <p>
                <span>Click button to login</span>
                <button onClick={login}>Login</button>
              </p>
            </div>
          case AuthenticationState.InProgress:
            return <p>Authenticating...</p>
          case AuthenticationState.Authenticated:
            if (accountInfo)
              return <context.Provider value={{ msalAuthProvider, accountInfo }}>
                <ZustimmungHygieneRichtlinie logout={logout}/>
              </context.Provider>    
      }
      }}
    </AzureAD>
  </StrictMode>
}

export default App
