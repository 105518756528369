import ZustimmungRequestDataItem from '../data/zustimmungRequestDataItem'
export const GetZustimmungHygieneRichtlinie = async (accessToken: string): Promise<ZustimmungRequestDataItem> => {
    const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/students/Stammdaten/ZustimmungHygieneRichtlinie`,
        {
            method: 'get',
            headers: new Headers(
                {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'text/plain; charset=utf-8'
                })
        })

    return {
        userValid: response.status !== 403,
        apiConnectionOK: response.status !== 400,
        datumZustimmung: response.status === 204 ? null : await response.text(),
        isLoading:false
    } as ZustimmungRequestDataItem
}
export const SetZustimmungHygieneRichtlinie = async (accessToken:string) : Promise<Boolean>=>{
    const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/students/Stammdaten/zustimmunghygienerichtlinie`,
        {
            method: 'post',
            headers: new Headers(
                {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'text/plain; charset=utf-8'
                })
        })
        const result = await response.text()
        
    return response && response.status === 200 && result === 'true'
}