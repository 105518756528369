import React, { FC } from "react"
import styles from './styles/zustimmungHygieneRichtlinie.module.css'
interface Props {

}
const Header: FC<Props> = () => {
    return (<div className={styles.header}>
        <div className={styles.banner}>
            <a href="http://www.fh-krems.ac.at" className={styles.logo} target="_blank"
                rel="noopener noreferrer" title="Startseite">
                <img src="https://www.fh-krems.ac.at/fileadmin/bsdist/theme/img/imc-fh-krems_logo_weiss.png"
                    alt="IMC Fachhochschule Krems GesmbH - University of Applied Sciences Austria" />
            </a>
        </div>            
    </div>)
}
export default Header
