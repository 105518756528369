import React, { FC } from 'react'
import styles from './styles/hygieneRichtlinieText.module.scss'

interface Props {
}
const HygieneRichtlinieText: FC<Props> = (props) => {
    return (
        <React.Fragment>
            <h1>Zustimmung COVID-19 Hygienemaßnahmen und Richtlinien für Präsenzlehrbetrieb praktische Übungen und Laborübungen IMC FH Krems</h1>
            <div className={styles.hygieneRichtlinieText}>
                <div className={styles.header}>Hygienemaßnahmen und Richtlinien:</div>
                <div className={styles.span}>Wir freuen uns, dass der <b>Präsenz Lehrbetrieb</b>, die <b>fachpraktischen Übungen und die Laborübungen</b> wieder für Studierende aller Studiengänge an der IMC FH Krems möglich sein werden. Bitte prüfen Sie Ihre Stundenplanung aufmerksam wann und vor allem wo Ihre Lehrveranstaltungen stattfinden, da alle Gebäude der IMC FH Krems genutzt werden können. Bitte beachten Sie weiters, dass der Zutritt zu unserem Gebäude ausschließlich mit Ihrer freigeschaltenen Studierenden ID Karte möglich ist.</div>
                <div className={styles.span}>Ihre Gesundheit ist uns in dieser speziellen Zeit natürlich besonders wichtig, deshalb werden die Lehrveranstaltungen unter der <b>strikten Einhaltung spezieller Hygienemaßnahmen und Richtlinien</b> abgehalten. Wir halten uns hier an die Vorgaben und Empfehlungen des Bundesministeriums. Um die <b>Schutzmaßnahmen</b> für Studierende, Lehrende und Mitarbeiterinnen und Mitarbeiter zu garantieren,  wurden die folgende Hygienemaßnahmen und Richtlinien für die IMC FH Krems erarbeitet, die von allen berechtigten Personen, die in den Gebäuden der IMC FH Krems präsent sind, ausdrücklich einzuhalten sind. Eine Ansteckung mit COVID-19 soll damit verhindert werden.</div>
                <div className={styles.span}><b>Bitte lesen Sie unsere Richtlinien aufmerksam durch und nehmen Sie diese ausdrücklich untenstehend zur Kenntnis.</b></div>
                <div className={styles.span}>Sie können die <a href='https://processes.fh-krems.ac.at/ims.net/Common/GetDoc.aspx?modId=378722&lang=DE' target='_blank' rel="noopener noreferrer">Hygienerichtlinien</a> jederzeit in den COVID-19 FAQs am eDesktop nachlesen. Sollten die Links nicht funktionieren, bitte versuchen Sie einen alternativen Browser z.B. Google Chrome oder Microsoft Teams.</div>
                <div className={styles.span}>Bei Fragen zum Schedule wenden Sie sich bitte an <a href='mailto:studyservices@fh-krems.ac.at'>studyservices@fh-krems.ac.at</a>. Bei Fragen zu den Hygienemaßnahmen an <a href='mailto:info@fh-krems.ac.at'>info@fh-krems.ac.at</a></div>
                <div className={styles.span}>Wir wünschen Ihnen alles Gute und bleiben Sie gesund!
                    <br />Führungsteam IMC FH Krems</div>
                <div className={styles.footer}>
                    <b>Schützen Sie nicht nur sich selbst, sondern schützen und respektieren Sie auch alle anwesenden Personen durch die Einhaltung der Richtlinien und Maßnahmen!</b>
                </div>
            </div>
        </React.Fragment>
    )
}
export default HygieneRichtlinieText