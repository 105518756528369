import { MsalAuthProvider, LoginType } from "react-aad-msal"

export const msalAuthProvider = new MsalAuthProvider({
	auth: {
		authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_IDENTITY_AUTHORITY}/`,
		clientId: `${process.env.REACT_APP_MICROSOFT_IDENTITY_CLIENT_ID}`,							
		postLogoutRedirectUri: 'https://www.fh-krems.ac.at'
	},
	cache: {						
		storeAuthStateInCookie: true
	}
}, {
	scopes: [
		`api://${process.env.REACT_APP_MICROSOFT_IDENTITY_WEB_API_CLIENT_ID}/user_impersonation`
	]
}, {
	loginType: LoginType.Redirect	
})